.container.home {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  height: 100vh;
  padding: 2vw 4vw;
  overflow: hidden;
  
  display: grid;
  grid-template-columns: repeat(5,1fr);
  
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 20px;
  position: relative;
  grid-column-start: 1;
  grid-column-end: 6;
}

.search-input {
  font-size: 18px;
  padding: 10px 15px;
  border: 1px solid gray;
  border-radius: 10px;
  flex-grow: 1;
}

.home button.filter {
  background: blue;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  min-width: 80px;
}

.menu-content {
  position: absolute;
  right: 0;
  top: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 5px #7a7a7a;
  margin-block: 10px 20px;
  border-radius: 5px;
  padding-block: 12px;
  width: max-content;
  z-index: 1001;
  width: 216px;
}

.menu-content .row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 30px;
}

.menu-content .row button,
.menu-content .row a {
  font-size: 18px;
  padding: 8px 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.menu-content .row button.active {
  background-color: rgba(0, 0, 0, 0.092);
}

.menu-content .row button span,
.menu-content .row a span {
  margin-left: 10px;
  font-size: 18px;
  text-transform: capitalize;
}

.menu-content .row button:hover,
.menu-content .row a:hover {
  background: rgba(0, 0, 0, 0.092);
}

.data-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  grid-column-start: 2;
  grid-column-end: 6;
}

.items {
  width: 100%;
  height: 100%;
  margin: 20px;
}

/* .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-inline: auto;
  padding-inline: 15px;
  max-width: 1200px;
} */
