.side-bar { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 5px #7a7a7a;
    background-color: #eee;
    grid-column-start: 1;
    grid-column-end: 2;
}

.side-bar button {
    width: 100%;
    font-size: 18px;
    padding: 18px;
}

.side-bar button:hover,
.side-bar button:focus {
    background-color: rgba(0, 0, 0, 0.092);
}
