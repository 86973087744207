

.item {
    background: #ffffff;
    padding: 10px 20px;
    border: 1px solid gray;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 0 4px #ccc;
    margin-bottom: 10px;
}

.item h3 {
    font-size: 18px;
}

.item.location {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.item.location h3 {
    margin-bottom: 5px;
}

.item.location .locations {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
    padding-left: 10px;
}