
.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.social-container {
  margin: 20px 0;
  text-align: center;
}

.auth-links-container {
  margin-bottom: 8px;
}

.auth-fp-link {
  font-size: small;
  text-align: center;
}

@media only screen and (max-width: 1289px) {
  .auth-wrapper {
    width: 50%;
  }
}
@media only screen and (max-width: 1000px) {
  .auth-wrapper {
    width: 60%;
  }
}
@media only screen and (max-width: 829px) {
  .auth-wrapper {
    width: 80%;
  }
}
@media only screen and (max-width: 620px) {
  .auth-wrapper {
    width: 90%;
    top: 3em;
  }
  .auth-form-label {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .primary-label {
    font-size: 28px;
    font-weight: 700;
  }
  

  .auth-form-wrapper {
    display: block;
  }

  .auth-media {
    width: 100%;
  }

  .auth-form {
    width: 100%;
    padding: 22px 0px 8px 0px;
  }
}


button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}


#root {
  width: 100%;
}


/*start scrollbar */
::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px #ccc;
  border-radius: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #cdcdcd; /* #09c6f9 */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #adadad;
}

/* end scrollbar */

.block input[type="number"] {
  width: 75px;
}

.block input,
.notes textarea {
  background-color: #9f9f9f33;
  margin-right: 10px;
  border-radius: 8px;
  font-size: 16px;
  padding: 10px;
}
/* end basics */

/* @import url("./utils/style.css"); */

@media (max-width: 425px) {
  body {
    padding: 0;
  }
}

@media (max-width: 450px) {
  body {
    overflow: auto;
    margin: 10px;
    padding: 0 10px;
  }
}




